.speakerAnnouncement_body {
    background: transparent;
    color: #000;
    margin-top: 1rem;
}

.speakerAnnouncement_titleContainer {
    background: linear-gradient(to bottom, #8a000c, #520300);
    padding: .25rem 1rem;
    border-radius: .5rem;
    color: #fff;
}

.speakerAnnouncement_content {
    margin-left: 1rem;
}

.main_game {
    background: transparent;
    padding: 1rem 0 2rem;
}

.main_gameSelection .main_gameSelection_menu {
    background: #fff;
    color: #999;
    border: 1px solid #8a000c;
    border-radius: 10px;
    filter: drop-shadow(0px 1px 2px rgba(255, 0, 0, .3));
    margin: 5px;
}

.main_gameSelection .main_gameSelection_menu.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

.main_game_container {
    background: transparent;
    color: #000;
    border-radius: 10px;
}

.main_gameSelection .main_gameSelection_menu .main_gameSelection_image {
    /* filter: drop-shadow(2px 2px 3px rgb(0, 0, 0, 0.3)); */
    margin: 0.5rem;
}

.main_gameSelection_vertical .main_gameSelection_menu img {
    height: 4rem;
}

#Section_gameType .Section_gameType_body {
    background: transparent;
    /* padding-top: 1rem; */
}

#Section_gameType .card_image_wrap {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px 5px 5px 5px;
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#Section_gameType .content_container {
    background: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 5px 5px;
}

#Section_LIVE_CASINO .section_title {
    color: #8a000c;
}

#Section_LIVE_CASINO .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#Section_PROMOTION .section_title {
    color: #8a000c;
}

/* #Section_1675914698054 .Section_1675914698054_body {
    background: #f9f9f9;
}

#Section_1675914698054 .card_image_wrap {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px 5px 5px 5px;
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#Section_1675914698054 .content_container {
    background: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 5px 5px;
} */

.rankingList_body {
    background: transparent;
    padding: 0 0 1rem;
}

.rankingList_body .section_title {
    color: #8a000c;
}

.rankingList_table {
    background: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgba(255, 0, 0, .4) 1px 2px 4px);
}

.rankingList_table_header {
    background: linear-gradient(1turn, #efd098, #fbefd2);
    color: #956527;
    border-radius: 15px 15px 0px 0px;
    margin: .5rem .5rem 0;
    padding: 1rem;
}

/* .rankingList_table_container .rankingList_table_header+.rankingList_table_body {
    padding: 0;
} */

.rankingList_table_row {
    background: #fff;
    /* border-bottom: 1px solid #956527; */
    /* margin-left: 1rem; */
    /* margin-right: 1rem; */
}

.rankingList_username {
    font-weight: bold;
}

.rankingList_info {
    color: #f90c00;
}

.rankingList_amount {
    font-weight: bold;
}

.rankingList_table_container .rankingList_tableTab {
    background: linear-gradient(to bottom, #8a000c, #520300);
    border: 0;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
    padding: 0.5rem;
    width: 30rem;
    font-weight: 600;
}

.rankingList_tableTab .rankingList_table_selection {
    background: transparent;
    color: #fff;
    padding: .25rem 1rem;
}

.rankingList_tableTab .rankingList_table_selection.active {
    background: #fff;
    color: #8a000c;
    border-radius: 10px 10px 10px 10px;
}

.rankingList_table_container {
    background: #fff;
    border-radius: 10px;
    /* filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px); */
    padding: 1rem;
}

.rankingList_table_container .rankingList_table {
    background: #fff;
    border-radius: 10px 10px 10px 10px;
    filter: none;
    padding: 0;
}

.rankingList_table_container .rankingList_table_body {
    margin-top: 1rem;
}

.rankingList_table_container .rankingList_table_row {
    background: #fff;
    border-radius: 10px 10px 10px 10px;
    /* filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px); */
    margin-bottom: 1rem;
    padding: 1rem 1rem 1rem 0;
}

#Section_Service {
    padding: 2.5rem 0;
}

#Section_Service .section_title {
    color: #8a000c;
}

#Section_Service_m {
    padding: 2.5rem 0;
}

#Section_Service_m .section_header {
    padding-bottom: 1rem;
}

#Section_Service_m .section_title {
    color: #8a000c;
}

.contactUs_body {
    background: transparent;
    color: #000;
}

#contactUs_title.pageTitle {
    background: transparent;
    padding-top: 0;
    padding-bottom: 2.5rem;
}

.contact_card.card {
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), inset 0 0 5px 0 rgba(0, 0, 0, .2);
    border-top: 1px solid #8a000c;
    border-bottom: 1px solid #8a000c;
    border-left: 1px solid #8a000c;
    border-right: 1px solid #8a000c;
    margin: 10px;
    padding: 1rem;
}

#contactUs_title_m {
    background: transparent;
    color: #000;
    padding: 1.25rem;
}

.contact_container_m .contact_card.card {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    border-top: 0;
    border-bottom: 1px solid #808080;
    border-left: 0;
    border-right: 0;
    margin: 10px 10px 0 10px;
    padding: 0 1rem;
}

#promotion_title {
    background: transparent;
    color: #000;
}

#Section_promoContainer .Section_promoContainer_body {
    background: transparent;
}

#Section_promoContainer .card_container {
    border-bottom: 1px dashed rgba(0, 0, 0, .5);
    padding: 0.8rem 1.5rem;
}

#Section_promoContainer .card_image_wrap {
    border-radius: 0;
}

#Section_promoContainer .content_container {
    background: rgba(0, 0, 0, 0);
}

#Section_promoContainer .card_content_title {
    color: #8a000c;
}

#Section_promoContainer .button_action1 {
    background: transparent;
    color: #000;
    border: 1px solid #8a000c;
    border-radius: 5px;
}

#Section_promoContainer .button_action2 {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

#Section_promoContainer .filterTab {
    background: #efefef;
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.5rem;
}

#Section_promoContainer .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#Section_promoContainer .filterTab .filterTab_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

#Section_promoContainer .promotionCode_part {
    border: 1px solid #8a000c;
}

#Section_promoContainer .promotionCode_part label {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

#Section_promoContainer .promotionCode_part .promotionCode_content {
    background: #fff;
    color: #000;
}

#Section_promoContainer .promotionTag_part label {
    background: #efefef;
    color: #000;
    border-radius: 5px 5px 5px 5px;
    margin-right: .5rem;
    padding: .25rem .5rem;
}

/* #Section_promoContainer_2 .Section_promoContainer_2_body {
    background: rgba(249, 249, 249, 1);
}

#Section_promoContainer_2 .card_container {
    border-bottom: 1px dashed rgba(0, 0, 0, .5);
    padding: 0.8rem 1.5rem;
}

#Section_promoContainer_2 .card_image_wrap {
    border-radius: 0;
}

#Section_promoContainer_2 .content_container {
    background: rgba(0, 0, 0, 0);
}

#Section_promoContainer_2 .button_action1 {
    background: linear-gradient(360deg, rgba(239, 208, 152, 1) 0%, RGBA(251, 239, 210, 1) 100%);
    color: #000;
    border: 1px solid RGBA(251, 239, 210, 1);
    border-radius: 5px;
}

#Section_promoContainer_2 .button_action2 {
    background: linear-gradient(360deg, rgba(189, 0, 1, 1) 0%, RGBA(255, 67, 67, 1) 100%);
    color: rgba(255, 255, 255, 1);
    border: 1px solid RGBA(251, 239, 210, 1);
    border-radius: 5px;
}

#Section_promoContainer_2 .filterTab {
    background: #efefef;
    border-radius: 5px;
    margin-top: 0;
    padding: 0.5rem;
}

#Section_promoContainer_2 .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#Section_promoContainer_2 .filterTab .filterTab_selection.active {
    background: linear-gradient(1turn, #bd0001, #ff4343);
    color: #fff;
    border-radius: 5px;
}

#Section_promoContainer_2 .promotionCode_part {
    border: 2px solid rgba(189, 0, 1, 1);
    border-radius: 5px 5px 5px 5px;
}

#Section_promoContainer_2 .promotionCode_part label {
    background: rgba(0, 0, 0, 0);
    ;
    color: #000;
}

#Section_promoContainer_2 .promotionCode_part .promotionCode_content {
    background: rgba(189, 0, 1, 1);
    color: #fff;
}

#Section_promoContainer_2 .promotionTag_part label {
    background: #efefef;
    color: #000;
    border-radius: 5px 5px 5px 5px;
    margin-right: .5rem;
    padding: .25rem .5rem;
} */

.help_body {
    background: transparent;
    padding: 0 0 3rem;
}

#help_title {
    background: transparent;
    color: #000;
}

.help_accordion {
    padding: 1.5rem;
}

.help_accordion .accordion-item .accordion-button {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 1);
    border-radius: 5px 5px 5px 5px;
    /* filter: drop-shadow(0px 1px 2px rgba(255, 0, 0, .3)); */
    border-top: 1px solid #8a000c;
    border-bottom: 1px solid #8a000c;
    border-left: 1px solid #8a000c;
    border-right: 1px solid #8a000c;
}

.help_accordion .accordion-item .accordion-button:not(.collapsed) {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.help_accordion .accordion-item .accordion-collapse {
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 0 0 5px 5px;
    border-top: 0;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .3));
}

.help_accordion .accordion-item .accordion-body {
    background: #fff;
    border-radius: 0 0 5px 5px;
}

.login_body {
    background: transparent;
}

#login_title_d {
    background: rgba(255, 255, 255, 1);
    color: #8a000c;
    font-weight: bold;
}

#login_title_m {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

.login_container {
    background: #fff;
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);
}

.login_form .form-label {
    color: #000;
}

.login_btnLogin {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_body {
    background: transparent;
}

#register_title_d {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

#register_title_m {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

.register_container {
    background: #fff;
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);
}

.register_stepper button {
    background: rgb(224, 224, 224);
    color: #000;
}

.register_stepper .register_stepContainer.active button {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
}

.register_stepContainer:not(:first-child):before {
    background: rgb(224, 224, 224);
}

.register_stepContainer.active:not(:first-child)::before {
    background: #8a000c;
}

.register_form .form-label {
    color: #000;
}

.register_btnNext {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnBack {
    background: transparent;
    color: #000;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #8a000c
}

.register_btnDepositNow {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 20px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnSend {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}


.register_successful_content{
    font-weight: 600;
}

.forgotPassword_body {
    background: transparent;
}

#forgotPassword_title_d {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

#forgotPassword_title_m {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

.forgotPassword_container {
    background: #fff;
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);
}

.forgotPassword_form .form-label {
    color: #000;
}

.forgotPassword_btnNext {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnBack {
    background: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 1px solid #8a000c;
    border-bottom: 1px solid #8a000c;
    border-left: 1px solid #8a000c;
    border-right: 1px solid #8a000c;
}

.forgotPassword_btnSubmit {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnSend {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_body {
    background: #f9f9f9;
}

#forgotUsername_title_d {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

#forgotUsername_title_m {
    background: transparent;
    color: #8a000c;
    font-weight: bold;
}

.forgotUsername_container {
    background: #fff;
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);
}

.forgotUsername_form .form-control {
    color: #000;
}

.forgotUsername_btnSubmit {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_btnSend {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.myProfile_body {
    background: rgba(249, 249, 249, 1);
}

#myProfile_title {
    background: rgba(249, 249, 249, 1);
}

.myProfile_mySetting_row {
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
}

.myProfile_myAccount_row {
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
}

.slots_body {
    background: transparent;
}

#slot_title {
    background: transparent;
    color: #000;
    font-weight: bold;
}

.slots_body .title_container a+div select {
    border-radius: 20px;
    border: 1px solid #8a000c;
}

.gameProviderTab_container {
    background: transparent;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E2E2E2;
}

.gameProviderTab .gameProviderTab_selection {
    background: transparent;
    border-radius: 10px 10px 10px 10px;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    border-left: 1px solid #E2E2E2;
    border-right: 1px solid #E2E2E2;
}

.gameProviderTab .gameProviderTab_selection.active {
    background: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0, 1);
    border-top: 1px solid #8a000c;
    border-bottom: 1px solid #8a000c;
    border-left: 1px solid #8a000c;
    border-right: 1px solid #8a000c;
}

.gameProviderTab_container button {
    background: transparent;
    color: #8a000c;
}

.slotTypeTab {
    background: #e6e6e6;
    border-radius: 5px;
    color: #909090;
}

.slotTypeTab .slotTypeTab_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

#slotGameContainer .game_btnPlayNow {
    background: linear-gradient(to bottom, #8a000c, #520300);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

#slotGameContainer .card_image_wrap {
    border-radius: 1rem;
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#slotGameContainer .content_container {
    background: rgba(255, 255, 255, 0);
    color: rgba(0, 0, 0, 1);
    border-radius: 0px 0px 5px 5px;
}

.mslot_container {
    margin: 4rem 10rem;
}

.mslot_container_m {
    margin: 0 5rem 1rem;
}

.mslot_container .mslot_wrap {
    background: #fff;
    color: #000;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 2rem;
}

.mslot_container_m .mslot_wrap {
    background: transparent;
    color: #000;
    border-radius: 0;
    box-shadow: none;
    padding: 1.5rem;
}

.mslot_userInfo label {
    color: #8a000c;
    font-weight: 600;
}


.mslot_container .mslot_userInfo input {
    border-radius: .5rem;
    padding: 0.35rem 3rem 0.35rem 1rem;
    background: #fff;
    border: 1px solid #999;
}

.mslot_container .mslot_imageWrap img {
    height: 17rem;
  }

#mslotGameContainer .mslotGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#mslotGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#casinoGameContainer .casinoGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#casinoGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#casinoGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#sportGameContainer .sportGameContainer_body {
    background: transparent;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    padding-top: 1.5rem;
}

#sportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#sportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

#esportGameContainer .sportGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#esportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}


#esportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#fishingGameContainer .fishingGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#fishingGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#fishingGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}


#lotteryGameContainer .lotteryGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#lotteryGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}


#lotteryGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}


#boardGameContainer .boardGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#boardGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

#othersContainer .othersContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#othersContainer .content_container {
    background: rgba(231, 231, 231, 0);
}

.wallet_body {
    background: transparent;
}

.wallet_body .pageTitle {
    background: transparent;
    color: rgba(0, 0, 0, 1);
    padding-top: 0;
}

.wallet_container_title {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    /* box-shadow: 0px 1px 3px rgb(255, 0, 0, .4), 0px 0px 3px rgb(255, 0, 0, .4); */
}

.walletBar {
    background: #fff;
    color: #000;
    filter: drop-shadow(2px 3px 3px rgb(155, 155, 155, 1));
}

.walletBar_userVerification,
.walletBar_point {
    background: #f2f2f2;
}

.walletBar_selection {
    font-weight: 600;
}

/* .walletBar_selection.nav-link.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
} */

.walletBar_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

.wallet_container {
    /* border: 1px solid rgba(149, 101, 39, 1); */
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(2px 3px 3px rgb(155, 155, 155, 1));
}

.wallet_container_wrap {
    background: rgba(255, 255, 255, 1);
    border-radius: 0px 0px 10px 10px;
}

.wallet_btnSubmit,
.wallet_gameWallet_btnAllIn,
.wallet_gameWallet_btnRefresh {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_container .form-control:disabled {
    background: #e6e6e6;
    color: rgba(0, 0, 0, 1);
}

.wallet_reminder_container {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 1);
}

.wallet_reminder_title {
    color: red;
}

.table.wallet_history_table thead,
.myAccount_notification_header,
.myAccount_bankingDetails_tableHeader {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.table.wallet_history_table tbody,
.myAccount_notification_title_row,
.myAccount_bankingDetails_table {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 1);
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_btnAmount button {
    background: #fff;
    color: #000;
}

.wallet_btnAmount button.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

.myAccount_notification_content {
    background: #fff;
    color: #000;
}

.myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {
    background: red;
    color: #fff;
}

.myAccount_userInfo_profile label {
    color: #8a000c;
}

.transactionTypeTab {
    background: rgba(239, 239, 239, 1);
    border-radius: 5px;
    padding: 0.5rem;
}

.transactionTypeTab .transactionType_selection {
    background: rgba(239, 239, 239, 1);
    color: #000;
    border-radius: 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
}

.transactionTypeTab .transactionType_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

.wallet_body_container_m {
    padding: 0;
}

.wallet_container_m {
    border: 0;
    filter: none;
}

.wallet_container_m .wallet_container_wrap {
    background: transparent;
}

.wallet_container_m .wallet_reminder_container {
    background: transparent;
}

.walletBar_btnLogout {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px none;
    border-bottom: 0px none;
    border-left: 0px none;
    border-right: 0px none;
}

.depositBankImg img {
    border-radius: .375rem;
    border-top: 1px solid #8a000c;
    border-bottom: 1px solid #8a000c;
    border-left: 1px solid #8a000c;
    border-right: 1px solid #8a000c;
}

.shareLink_button {
    border-radius: 5px;
}

.realNameVerification_container .realNameVerification_viewSample_button {
    border-radius: 5px;
}

.friendRequest_tab {
    background: #f2f2f2;
    color: #909090;
    border-radius: 5px;

}

.friendRequest_tab .friendRequestTab_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
}

.coupon_body {
    background: transparent;
    padding: 0 0 3rem;
}

#coupon_title {
    background: transparent;
    color: #000;
}

#coupon .filterTab {
    background: #efefef;
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.5rem;
}

#coupon .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#coupon .filterTab .filterTab_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

.coupon_content_wrapper {
    color: #fff;
}

.coupon_body_container {
    color: rgba(0, 0, 0, 1);
}

.button_couponClaim {
    border-radius: 5px 5px 5px 5px;
    padding: 0.5rem;
}

.coupon_expireTime {
    color: red;
    margin-bottom: 1rem;
}

.coupon_tnc {
    color: #0d6efd;
}

.button_claimCoupon {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border: 1px solid RGBA(251, 239, 210, 1);
    border-radius: 5px;
    margin-top: .75rem;
    padding: .5rem;
}


.App,
.page {
    background: transparent
}

.myQRCode_body .shareQR_info {
    filter: drop-shadow(2px 2px 3px rgb(0, 0, 0, 0.3));
}

.shareQR_qrCode svg {
    border: 1px solid #8a000c;
    padding: .65rem;

}

.history_paginationButton button {
    background: #f0f0f0;
    color: #000;
    border-radius: .5rem;
}

.history_paginationButton button.paginationNumber {
    opacity: .5;
    color: #000;
}

.history_paginationButton button.paginationNumber.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    opacity: 1;
    color: #fff;
}

.card_wrap .game_new_icon{
    right: .5rem;
}