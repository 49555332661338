.font_h1 {
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: lighter;
}

.font_h2 {
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.font_h3 {
    font-size: 1.17rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h4 {
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h5 {
    font-size: 0.83rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h6 {
    font-size: 0.625rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_body {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_subtitle {
    font-size: 1.4rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_button {
    font-size: 0.75rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_caption {
    font-size: 1.2rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_overline {
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.color_primary {
    background: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
}

.color_secondary {
    background: linear-gradient(360deg, rgba(239, 208, 152, 1) 0%, RGBA(251, 239, 210, 1) 100%);
    color: rgba(150, 100, 23, 1);
}

.color_button {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #ffffff;
}

.color_title {
    background: transparent;
    color: #000000;
}

.color_text {
    background: transparent;
    color: #000000;
}

.page_color {
    background: transparent;
    color: #000000;
}

.color_active {
    background: transparent;
    color: #8a000c;
}

.contact_front_d {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

.contact_back_d .contact_back_d_header {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 8px 0 0 0;
}

.contact_back_d .contact_back_d_item {
    background: rgba(249, 249, 249, 1);
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.contact_back_d.card>.list-group:last-child {
    border-radius: 0 0 0 8px;
}


.announcement_body {
    border-radius: 5px 5px 5px 5px;
}

.announcement_header.modal-header {
    border-bottom: 0;
}

.announcement_header img {
    filter: drop-shadow(1px 2px 0px black);
}

.announcement_btnClose {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

header {
    filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.1));

}

.header_topBar {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

.header_menu {
    color: #a7a9ac;
    background: linear-gradient(to bottom, #797979, #ffffff, #ffffff);
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

.header_menu_page .header_menu_content {
    color: #909090;
    margin-right: .25rem;
}

.header_menu .header_menu_page .header_menu_content.active {
    background: transparent;
    color: #8a000c;
    /* border-bottom: 3px solid #966417; */
}

.banking_dropdown a li,
.claim_dropdown li {
    background: #fff;
    color: 8080880;
}

.banking_dropdown a.active li {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

.header_btnLogin {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
}

.header_btnRegister {
    background: linear-gradient(to left, #de9f17, #cf9a15, #fff68a, #ffcf45);
    color: #000;
    border-radius: 5px;
}

.header_btnLogout {
    background: linear-gradient(to left, #de9f17, #cf9a15, #fff68a, #ffcf45);
    color: #000;
    border-radius: 5px;
}

.header_btnRestore {
    background: linear-gradient(to left, #de9f17, #cf9a15, #fff68a, #ffcf45);
    color: #000;
    border-radius: 5px;
}

.header_btnBanking {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
}

.header_btnPromoClaim {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}

.header_topBar+.header_banking_info {
    box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 50%);
}

.header_sideBar_topBar {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.3));
}

.navbar-toggler {
    color: #fff;

}

.header_sideBar .header_sideBar_body {
    background: #f9f9f9;
    color: #4d4d4d;
}

.header_sideBar_menu .header_menu_content,
.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {
    background: transparent;
    color: #000;
    border-bottom: 1px solid #ccc;
    margin: 0 .85rem;
}

.header_sideBar_menu .header_menu_content.active {
    background: transparent;
    color: #8a000c;
    border-bottom: 1px solid #ccc;
}

/* .header_banking_info .header_banking_menu_list  {
   font-weight: 600;
} */

/* .header_banking_info .header_banking_menu_list a {
    opacity: .5;
} */

.header_banking_info .header_banking_menu_list .active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    border-radius: 5px;
    color: #fff;
}
.header_banking_info .header_banking_menu_list span.active {
    background: transparent;
}
#promoClaimModal label {
    color: #8a000c;
}

#promoClaimModal .filterTab {
    background: #efefef;
    color: #000;
    border-radius: 5px;
}

#promoClaimModal .filterTab .filterTab_selection.active {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    border-radius: 5px;
}


.header_topBar_wrap .header_banking_info .header_balance,
.header_topBar_wrap .header_bottom_row .header_balance {
    background: linear-gradient(to left, #de9f17, #cf9a15, #fff68a, #ffcf45);
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
    background: transparent;
    outline: 1px solid #fff;
    color: #fff;
}

.footer {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
}

.footer_menu_wrap {
    border-bottom: 1px dashed #fff;
}

.footer_payment_method .footer_info_img li:first-of-type img {
    filter: brightness(0) invert(1);
}

.btnCancel {
    background: #fff;
    color: rgba(0, 0, 0, 1);
    border: 1px solid #8a000c;
    border-radius: 5px 5px 5px 5px;
}

.modal .confirm_button {
    background: linear-gradient(180deg, #8a000c, #520300);
    color: #fff;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.modal .cancel_button {
    background: transparent;
    color: #000;
    border: 1px solid #8a000c;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.tab_pills_color.nav-pills .nav-link:hover {
    color: #8a000c;
}

.footer_help a:hover,
.header_banking_menu_list a:hover,
.header_menu_right .header_menu_page:hover,
.walletBar_selection:hover,
.banking_dropdown a li:hover,
.header_menu_page .header_text:hover,
.claim_dropdown li:hover {
    color: #8a000c;
}

.header_menu_page .header_text:hover {
    color: #8a000c;
}

.footer_help a:hover {
    color: #fff;
}

.scrollToTopButton {
    background: linear-gradient(180deg, #8a000c, #520300);
    color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    padding: .375rem .75rem;
}

.swiper .swiper-pagination-bullet {
    background: #8a000c;
}

.alertMessage_content svg {
    color: #8a000c;
}

.btnCancelPromotion {
    background: linear-gradient(180deg, #8a000c, #520300);
    color: #fff;
}

.modal-header {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

#gameWalletModal .modal-header {
    background: transparent;
}

#gameWalletModal .game_wallet_dropdown table tbody tr {
    background: #e2e2e2;
}

.alertMessage_header.modal-header {
    background: transparent;
    color: #8a000c;
}
.header_banking_info .header_banking_info_top .header_balance_currency{
    font-weight: 600;
}

.header_banking_info .header_banking_info_top .header_balance_currency span:nth-child(2){
    color: #8a000c;
}

.header_info_section {
    color: #fff;
}

.header_banking_info .header_banking_info_top .header_balance_icon {
    color: #000;
}

.modal-dialog .modal-header .btn-close {
    background-image: var(--btn-close-bg-white);
    opacity: 1;
}

.gameWallet_accordion .gameWallet_accordion_icon {
    background: linear-gradient(to bottom, #8a000c, #520300);
}

.banking_dropdown a li:hover {
    background: linear-gradient(to bottom, #8a000c, #520300);
    color: #fff;
}

.btnAfterVerify{
    border: 0;
}